import { Link } from 'gatsby';
import Cite from "../../../../src/components/cite";
import panel from "../../../../posts/orienting-a-solar-panel/panel-on-roof.jpg";
import { DemonstratePower, DemonstrateControl } from "../../../../posts/orienting-a-solar-panel/panel";
import PowerCurve from "../../../../posts/orienting-a-solar-panel/power-curve";
import * as React from 'react';
export default {
  Link,
  Cite,
  panel,
  DemonstratePower,
  DemonstrateControl,
  PowerCurve,
  React
};