const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 
  'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const decorations = letters.reduce(
  (acc, l) => ({ 
    ...acc,
    [`\\cal${l}`]: `\\mathcal{${l}}`,
    [`\\rm${l}`]: `\\mathrm{${l}}`,
    [`\\bb${l}`]: `\\mathbb{${l}}`,
    [`\\scr${l}`]: `\\mathscr{${l}}`,
    '\\T': '\\top',
    '\\rmd': '{\\rm d}',
    '\\defeq': '\\coloneqq',
    '\\eqdef': '\\eqqcolon',
    '\\X': 'X',
    '\\Prb': '\\rmP',
    '\\Exp': '\\rmE',
    '\\R': '\\bbR',
    '\\RR': '\\scrR',
    '\\im': '{\\rm i}',
    '\\vectorSpace': '\\bbV',
    '\\vectorAlgebra': '\\scrV',
    '\\stateSpace': '\\bbX',
    '\\stateAlgebra': '\\scrX',
    '\\stateVar': 'x',
    '\\markVar': 'y',
    '\\momentVar': 'u',
    '\\pathVar': '\\xi',
    '\\TT': 'T',
  }),
  {}
);
module.exports = {
  macros: {
    ...decorations,
    '\\var': '\\operatorname{var}',
  },
};
