import { Link } from 'gatsby';
import Cite from "../../../../src/components/cite";
import Proof from "../../../../src/components/proof";
import Diagram from "../../../../posts/generative-probability/diagram";
import * as React from 'react';
export default {
  Link,
  Cite,
  Proof,
  Diagram,
  React
};