module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/mvarble/dropbox/Dropbox/development/websites/matthew-varble-site/node_modules/gatsby-remark-embed-snippet","id":"b642e409-12e0-5a79-b181-a4785165268c","name":"gatsby-remark-embed-snippet","version":"7.2.0","modulePath":"/home/mvarble/dropbox/Dropbox/development/websites/matthew-varble-site/node_modules/gatsby-remark-embed-snippet/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/mvarble/dropbox/Dropbox/development/websites/matthew-varble-site/node_modules/gatsby-remark-prismjs","id":"abc0bc3f-a555-557b-a91b-04a115ddd4fa","name":"gatsby-remark-prismjs","version":"6.2.0","modulePath":"/home/mvarble/dropbox/Dropbox/development/websites/matthew-varble-site/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"aliases":{"jl":"julia"}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"remarkPlugins":[null,[null,{"block":["indentedCode"]}]],"rehypePlugins":[null,null,[null,{"content":{"type":"element","tagName":"i","properties":{"className":["fas","fa-link","fa-xs"]},"children":[]},"properties":{}}]],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/mvarble/dropbox/Dropbox/development/websites/matthew-varble-site","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
