import { imageWrap, left } from "../../../../posts/introduction-to-frames/index.module.scss";
import { R3Diagram, TwoFrameDiagram, ChickenLocation, EricAtSam, EricRightStep, Translation, Rotation, Scale } from "../../../../posts/introduction-to-frames/frame-diagrams";
import { EarthYear, EarthTilt, EarthSpin, MapCoordinates, EarthPosition } from "../../../../posts/introduction-to-frames/solar";
import * as React from 'react';
export default {
  imageWrap,
  left,
  R3Diagram,
  TwoFrameDiagram,
  ChickenLocation,
  EricAtSam,
  EricRightStep,
  Translation,
  Rotation,
  Scale,
  EarthYear,
  EarthTilt,
  EarthSpin,
  MapCoordinates,
  EarthPosition,
  React
};