import negx from './negx.jpg';
import negy from './negy.jpg';
import negz from './negz.jpg';
import posx from './posx.jpg';
import posy from './posy.jpg';
import posz from './posz.jpg';

const output = [posx, negx, posy, negy, posz, negz];
export default output;


