import React from 'react';
import Prism from 'prismjs/components/prism-core';
import 'prismjs/components/prism-python';

const CODE = 
`import numpy
X = numpy.random.exponential(scale=1/69.0)
Y = numpy.random.normal(0.0, 1.0)
Z = numpy.random.normal(Y, X)
W = lambda t: t*X + Y + Z`;

const LINES = CODE.split('\n');

export default function Diagram() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <State line={ 1 } stack="[X]"/>
      <div style={{ margin: '1em auto' }}>&darr;</div>
      <State line={ 2 } stack="[X,Y]"/>
      <div style={{ margin: '1em auto' }}>&darr;</div>
      <State line={ 3 } stack="[X,Y,Z]"/>
    </div>
  );
}

function State({ line, stack }) {
  return (
    <div className="card" style={{ margin: '1em auto', maxWidth: '500px' }}>
      <Code code={ LINES.slice(0, line + 1).join('\n') } />
      <div style={{ margin: '1em' }}>
        Memory: <code className="language-text">M{ line }={ stack }</code>
      </div>
    </div>
  );

}

function Code({ code }) {
  // use prism to highlight the code block
  const ref = React.useRef();
  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.innerHTML = 
      Prism.highlight(code, Prism.languages.python, 'python');
  }, [ref, code]);

  // render the code block
  return <pre style={{ borderBottom: '1px solid black' }}><code ref={ ref } /></pre>;
}
