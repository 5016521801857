import create from 'zustand';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';

const fp = { set, update };

const useCitations = create(set => ({
  numbers: {},
  uses: {},
  setNumbers: obj => set(fp.set('numbers')(obj)),
  reference: (bibKey, id) => set(
    fp.update(`uses[${bibKey}]`)(val => Math.max(val || 0, id))
  ),
}));

export default useCitations;
